import * as React from "react";
import Head from "../components/Head";
import Layout from "../components/layout";

export default function NotFound() {
  return (
    <Layout>
      <Head
        title={
          "404.Stephani Moroni Bishop - Senior Software UI Engineer"
        }
        keywords={[
          "engineer",
          "female",
          "senior",
          "UI",
          "react",
          "typescript",
          "OOP",
          "mobx",
          "observables",
          "css-in-js",
          "glamour",
          "emotion",
        ]}
      />
      <h1>404 Page Not Found</h1>
    </Layout>
  );
}
